.write {
    padding-top: 50px;
}

.writeImg {
    
    width: 100%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;

}

.writeForm {
    /* position: relative; */
}

.writeFormGroup {
    /* margin-left: 150px; */
    /* display: flex;
    align-items: center; */

}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(121, 118, 119);
    cursor: pointer
}

.writeInput {
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;
}

.writeInput:focus {
    outline: none;
}

.writeText {
    font-size: 20px;
}

.writeSubmit {
    /* position: absolute;
    top: 20px;
    right: 50px; */
    width: 85%;
    color:white;
    background-color: #185ADB;
    padding: 0px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-right: 10px;

}