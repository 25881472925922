

header.aboutMasthead {
    position: relative;
  margin-bottom: 3rem;
  padding-top: calc(8rem + 57px);
  padding-bottom: 8rem;
  background: no-repeat center center;
  background-size: cover;
  background-attachment: scroll;
}

header.aboutMasthead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.1;
  }



  .aboutTitles {
      color: white;
      text-align: center;
      
  }

  .aboutTitle {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 0.625rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .aboutSubheading {
    font-size: 2rem;
    font-weight: 300;
    margin: 0.75rem 0 2rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color:white;

  }

  .aboutContent {
      font-size: 20px;
      color: #212529;

  }




