

.singlePostImg {
    width: 100%;
    height: 440px;
    border-radius: 5px;
    object-fit: cover;
    margin-top: 15px;
    margin-bottom: 15px;
}

.singlePostTitle {
    font-size: 28px;
}

.singlePostTitleInput {
    font-size: 28px;
    border: none;
    color: gray;
    border-bottom: 1px solid lightgray;
    margin-bottom: 3px;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.editIcon {
    color:teal;
}

.deleteIcon {
    color: #950101;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #b39656;
    font-family: "Varela Round", sans-serif;
}

.singlePostContent{
    color: #666;
    font-size: 18px;
    line-height: 25px;
    white-space: pre-line;

}

.singlePostDescInput {
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    border: none;
    padding: 20px;
    width: 50vw;
    height: 300px;
}

.singlePostDescInput:focus {
    outline: none;
}

.singlePostContent::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.singlePostButton {
    width: 150px;
    border: none;
    background-color: cornflowerblue;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}
