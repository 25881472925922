

.nav-item {
    margin-right: 30px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

}

.nav-top{
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 999;

}