

header.masthead {
    position: relative;
    margin-bottom: 3rem;
    padding-top: calc(8rem + 57px);
    padding-bottom: 8rem;
    background: no-repeat center center;
    background-size: cover;
    background-attachment: scroll;
}


header.masthead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.4;

  }



  .headerTitles {
      color: white;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 4rem;
      
  }

  .headerTitle {
    font-size: 4.5rem;
    font-weight: 800;
    margin-top: 0.625rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .headerSubheading {
    font-size: 2rem;
    font-weight: 400;
    margin: 0.75rem 0 2rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color:white;

  }

  



