.footer-pin {
    text-align: center;
    width: 100%;
    padding: 5px;
    background-color: #DFE6E9;
    position: relative;
    left: 0;
    bottom: 0;



}

.footer-paragraph {
    font-family: 'Varela Round', sans-serif;
    font-size: 12px;
    color: #666;
    margin: 0px;
}

