

header.contactMasthead {
    position: relative;
    margin-bottom: 3rem;
    padding-top: calc(8rem + 57px);
    padding-bottom: 8rem;
    background: no-repeat center center;
    background-size: cover;
    background-attachment: scroll;
}

header.contactMasthead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.55;
}



.contactTitles {
    color: white;
    text-align: center;

}

.contactTitle {
    font-size: 4rem;
    font-weight: 800;
    margin-top: 0.625rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.contactSubheading {
    font-size: 2rem;
    font-weight: 300;
    margin: 0.75rem 0 2rem;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color:white;

}


.contactIntro {
    color: #666;
    font-size: 18px;
    line-height: 25px;

}

.contactButton {
    margin-top: 20px;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.formLabel {
    visibility: hidden;
}
