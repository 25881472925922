.sidebar {
    flex: 3; 
    margin: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    /* width: 300px; */
    border-top: 1px solid #a7a4a4 ;
    border-bottom: 1px solid #a7a4a4 ;
    font-family: 'Varela Round', sans-serif;
    font-size: 16px;
    color: #222;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.sidebarImage {
    margin-top: 15px;
    width: 80%;

}

.sidebarParagraph {
    padding: 20px;
    width: 80%;
}

.sidebarList {
    list-style: none;
    margin-bottom: 30px;
}

.sidebarListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebarIcon {
    font-size: 32px;
    padding: 5px;
    cursor: pointer;
    color: grey;

}